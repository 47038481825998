<template>
  <div>
    <iframe
      frameborder="0"
      style="height: 100vh; width: 100%"
      :src="url"
    />
    <base-modal
      :has-footer="false"
      :has-header="false"
      :close-on-overlay-click="false"
      :with-button-close="false"
      :is-open="isOpenModalLoading"
    >
      <template #body>
        <c-box
          m="16px"
          display="flex"
          flex-direction="column"
          align-items="center"
          text-align="center"
        >
          <c-circular-progress
            is-indeterminate
            color="primary"
            margin-bottom="16px"
            :size="['sm', 'md'].includes(currentBreakpoint) ? '50px' : '70px'"
          />
          <BaseText
            size-mobile="12px"
            size-desktop="14px"
          >
            Mohon menunggu,
            <br>
            Transaksi anda sedang diproses
          </BaseText>
        </c-box>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
// import BaseButton from '@/components/elements/base-button.vue'
import { mapActions } from 'vuex'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import { ENV } from '@/constants/config'

export default {
  components: {
    // BaseButton,
    BaseText,
    BaseModal,
  },
  mixins: [mixinsCheckBreakpoints()],
  data() {
    return {
      isOpenModalLoading: false,
      interval: null,
    }
  },
  computed: {
    url() {
      return this.$route?.query?.redirectUrl
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    const { redirectUrl } = this.$route?.query || {}
    const that = this

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js')
    recaptchaScript.setAttribute('id', 'midtrans-script')
    recaptchaScript.setAttribute('data-environment', ENV.MIDTRANS_DATA_ENVIRONMENT)
    recaptchaScript.setAttribute('data-client-key', ENV.MIDTRANS_DATA_CLIENT_KEY)
    document.head.appendChild(recaptchaScript)

    recaptchaScript.onload = () => {
      const options = {
        // callbackUrl,
        onSuccess: function() {
          that.isOpenModalLoading = true
          that.init()
        },
        onFailure: function() {
          that.isOpenModalLoading = true
          that.init()
        },
        onPending: function() {
          that.isOpenModalLoadingq = true
          that.init()
        },
      }
      window?.MidtransNew3ds?.authenticate?.(redirectUrl, options)
    }
  },
  methods: {
    ...mapActions({
      getTransaction: 'checkout/getTransaction',
    }),
    async init() {
      this.interval = setInterval(async() => {
        try {
          const { transactionId, flagRoute } = this.$route?.query || {}

          const res = await this.getTransaction(transactionId)
          const programId = res.data?.programId
          const isChooseNutritionist = res.data?.isChooseNutritionist ?? 1

          if (res.data?.status === 'pending') {
            return
          }

          clearInterval(this.interval)

          if (res.data?.status === 'failed' && flagRoute === 'buy-program') {
            this.$router.replace(`/buy-program/3?transaction_id=${transactionId}`)
          } else if (res.data?.status === 'failed') {
            this.$router.replace(flagRoute ? `/buy/4?transaction_id=${transactionId}&s=failed` : `/checkout/done?transaction_id=${transactionId}&s=failed`)
            return
          }

          if (flagRoute === 'buy-program') {
            this.$router.replace(`/buy-program/3?transaction_id=${transactionId}`)
          } else if (flagRoute) {
            this.$router.replace(`/buy/4?transaction_id=${transactionId}`)
          } else {
            this.$router.replace(isChooseNutritionist === 0 ? `/checkout/done?transaction_id=${transactionId}&program_id=${programId}&choose_nutri=${isChooseNutritionist}` : `/checkout/done?transaction_id=${transactionId}`)
          }
        } catch (e) {
          console.error(e)
        }
      }, 2000)
    },
  },
}
</script>
