import { render, staticRenderFns } from "./redirect-midtrans-3ds.vue?vue&type=template&id=53df96cc&"
import script from "./redirect-midtrans-3ds.vue?vue&type=script&lang=js&"
export * from "./redirect-midtrans-3ds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CCircularProgress: require('@chakra-ui/vue').CCircularProgress, CBox: require('@chakra-ui/vue').CBox})
