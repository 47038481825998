var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('iframe', {
    staticStyle: {
      "height": "100vh",
      "width": "100%"
    },
    attrs: {
      "frameborder": "0",
      "src": _vm.url
    }
  }), _c('base-modal', {
    attrs: {
      "has-footer": false,
      "has-header": false,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "is-open": _vm.isOpenModalLoading
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "m": "16px",
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "text-align": "center"
          }
        }, [_c('c-circular-progress', {
          attrs: {
            "is-indeterminate": "",
            "color": "primary",
            "margin-bottom": "16px",
            "size": ['sm', 'md'].includes(_vm.currentBreakpoint) ? '50px' : '70px'
          }
        }), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px"
          }
        }, [_vm._v(" Mohon menunggu, "), _c('br'), _vm._v(" Transaksi anda sedang diproses ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }